import { resize } from "../../shared/utils";
function getCss({ style: { size_scale } }) {
  const scale = initial => resize(initial, size_scale);
  return [
    {
      selector: "",
      declarations: {
        display: "flex",
        position: "relative",
        fontFamily:
          "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        fontSize: `${scale(14)}px`,
      },
    },
    {
      selector: " .language-list",
      declarations: {
        position: "absolute",
        zIndex: "1",
        opacity: "0",
        pointerEvents: "none",
        transition: "opacity .2s",
        background: "#fff",
        borderRadius: "8px",
        padding: `${scale(10)}px`,
      },
    },

    {
      selector: " .language-list.open",
      declarations: {
        opacity: "1",
        pointerEvents: "auto",
      },
    },
    {
      selector: " .language-list:after",
      declarations: {
        content: "''",
        position: "absolute",
        width: `${scale(20)}px`,
        height: `${scale(12)}px`,
        border: `${scale(12)}px solid transparent`,
        boxSizing: "border-box",
      },
    },
    {
      selector: " .language-list.wg-right:after",
      declarations: {
        left: `${scale(12)}px`,
      },
    },
    {
      selector: " .language-list.wg-left:after",
      declarations: {
        right: `${scale(12)}px`,
      },
    },
    {
      selector: " .language-list.wg-down",
      declarations: {
        boxShadow:
          "0 -50px 100px -20px rgba(50,50,93,0.25),0 -30px 60px -30px rgba(0,0,0,0.3)",
        bottom: `${scale(-12)}px`,
        transform: "translateY(100%)",
      },
    },
    {
      selector: " .language-list.wg-down:after",
      declarations: {
        top: `${scale(-23)}px`,
        borderBottom: `${scale(12)}px solid #fff`,
      },
    },
    {
      selector: " .language-list.wg-right",
      declarations: {
        left: "0px",
      },
    },
    {
      selector: " .language-list.wg-left",
      declarations: {
        right: "0px",
      },
    },
    {
      selector: " .language-list.wg-up",
      declarations: {
        boxShadow:
          "0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3)",
        bottom: `${scale(38)}px`,
      },
    },
    {
      selector: " .language-list.wg-up:after",
      declarations: {
        bottom: `${scale(-23)}px`,
        borderTop: `${scale(12)}px solid #fff`,
      },
    },
    {
      selector: " .language-icon",
      declarations: {
        width: `${scale(16)}px`,
        height: `${scale(16)}px`,
        marginRight: `${scale(5)}px`,
      },
    },
    {
      selector: " .language-option",
      declarations: {
        display: "flex",
        alignItems: "center",
        padding: `${scale(7)}px`,
        width: "100%",
        borderRadius: "3px",
      },
    },
    {
      selector: " .language-option.active-language",
      declarations: {
        padding: `0px ${scale(10)}px`,
      },
    },
    {
      selector: " .language-option a",
      declarations: {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        width: "max-content",
      },
    },
    {
      selector: " img.wg-flag",
      declarations: {
        height: `${scale(15)}px`,
        borderRadius: `${scale(3)}px`,
        width: "auto",
        filter: "brightness(1.1)",
        marginRight: `${scale(10)}px`,
      },
    },
  ];
}

export default getCss;
