import { h } from "preact";
import { useState } from "preact/hooks";

import { getLanguageName, getColor, keycodes } from "../../shared/utils";

import Flag from "../../shared/components/Flag";

const Option = ({
  styleOpts,
  colors,
  language,
  onClick = null,
  url,
  isFocused = false,
  active = false,
  focusRef = null,
  tabIndex,
}) => {
  const {
    with_name = true,
    full_name = true,
    with_flags,
    flag_type,
  } = styleOpts;
  const languageName = getLanguageName(language);
  const name = full_name ? languageName : language.toUpperCase();

  function handleClick(e, l) {
    e.preventDefault();
    if (onClick) {
      onClick(l);
    }
  }
  const [hover, setHover] = useState(false);

  const { textColor } = getColor(colors, {
    active,
    hover: hover || isFocused,
  });

  return (
    <div
      className={`language-option${active ? " active-language" : ""}`}
      {...(active
        ? {
            "aria-label": `Language selected: ${languageName}`,
          }
        : {
            role: "option",
          })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      tabIndex={tabIndex}
      onClick={e => handleClick(e, language)}
      onKeyDown={e => {
        if (e.keyCode === keycodes.enter) {
          handleClick(e, language);
        }
      }}>
      <a
        href={url}
        {...(!with_name && { "aria-label": name })}
        tabIndex={-1}
        style={{ color: textColor }}
        {...(isFocused && !active && { ref: focusRef })}
        id={`weglot-language-${language}`}>
        {active && (
          <svg className="language-icon" viewBox="0 0 16 16">
            <path
              d="M7.5 2C10.91 2 13 4.01 13 6.5c0 2.07-1.45 3.82-3.92 4.34L6.62 13.3a.66.66 0 0 1-1.12-.46v-2.1C3.29 10.12 2 8.45 2 6.5 2 4.01 4.09 2 7.5 2z"
              fill="#333333"
              fill-rule="evenodd"
            />
          </svg>
        )}
        {with_flags && !active && (
          <Flag language={language} flagType={flag_type} />
        )}
        {with_name && name}
      </a>
    </div>
  );
};

export default Option;
